import React from 'react';
import moment from 'moment';
import AccountIcon from '@material-ui/icons/SupervisorAccount';
import InformationCard from 'views/contexts/modal/card';


const BasicInfo = (props) => {
  const {
    userName,
    englishName,
    gender,
    birthDate,
    phoneNumber,
    email,
    address,
    departments = [],
    otherAssignNgo,
    otherAssignMedical,
  } = props.data;

  const birthString = moment(birthDate).format("Y년 M월 D일");
  const inputData = [
    ['이름', `${userName} (${englishName})`],
    ['성별', `${gender === 'male' ? '남' : gender === 'female' ? '여' : '기타'}`],
    ['생년월일', `${birthString}`],
    ['전화번호', `${phoneNumber}`],
    ['이메일', `${email}`],
    ['주소', `${address}`],
  ];
  departments.forEach((department, index) => {
    inputData.push([`지원 팀 (${index+1}지망)`, `${department.departmentName} ${department.teamName}`]);
    inputData.push([`지원 사업 (${index+1}지망)`, department.medicalField]);
  });
  inputData.push(['타팀 배정 가능', `${otherAssignNgo ? 'O' : 'X'}`]);
  inputData.push(['타사업 배정 가능', `${otherAssignMedical ? 'O' : 'X'}`]);

  return (
    <InformationCard
      title={(
        <span className="Title">
          <AccountIcon className='Icon'/>
          기본정보
        </span>)}
      content={(
        <div className="Content">
          {inputData.map(input => (
            <div className="Content__wrapper" key={input}>
              <span className="Head-3">{input[0]} : </span> <span className="SubContent"> {input[1]} </span>
            </div>
          ))}
        </div>
      )}>
    </InformationCard>
  )
}

export default BasicInfo;
