export const applyInfo = [
  {
    key: 'name',
    value: '이름',
  },{
    key: 'englishName',
    value: '영문이름',
  },{
    key: 'gender',
    value: '성별',
  },{
    key: 'birthDate',
    value: '생년월일',
  },{
    key: 'phoneNumber',
    value: '전화번호',
  },{
    key: 'email',
    value: 'Email',
  },{
    key: 'academicName',
    value: '학교'
  },{
    key: 'major',
    value: '학과'
  },{
    key: 'entranceYear',
    value: '입학연도'
  },{
    key: 'graduationYear',
    value: '졸업연도'
  },{
    key: 'address',
    value: '주소'
  },{
    key: 'departmentName_1',
    value: '1지망'
  },{
    key: 'departmentName_2',
    value: '2지망',
  },{
    key: 'medicalField_1',
    value: '사업 1지망'
  },{
    key: 'medicalField_2',
    value: '사업 2지망'
  },{
    key: 'otherAssignNgo',
    value: '타팀 배정'
  },{
    key: 'otherAssignMedical',
    value: '타사업 배정'
  },{
    key: 'evaluation',
    value: '평가 상태'
  }
]
export const interview = [
{
  key: 'name',
  value: '이름',
},{
  key: 'phoneNumber',
  value: '전화번호',
},{
  key: 'department_1',
  value: '1지망'
},{
  key: 'department_2',
  value: '2지망',
},{
  key: 'medicalField_1',
  value: '사업 1지망'
},{
  key: 'medicalField_2',
  value: '사업 2지망'
},{
  key: 'otherAssignNgo',
  value: '타팀 ',
},{
  key: 'otherAssignMedical',
  value: '타사업',
}];

export const question = [{
  key: 'departmentName',
  value: '본부',
}, {
  key: 'teamName',
  value: '팀',
}, {
  key: 'content',
  value: '질문내용',
  width: '300px',
}, {
  key: 'register',
  value: '등록자',
}, {
  key: 'registerDate',
  value: '등록일자', 
}, {
  key: 'type',
  value: '유형'
}]