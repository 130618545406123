import React, { Component } from 'react';
import Table from 'views/contexts/table';
import * as axios from 'lib/api/recruit';
import Modal from 'views/contexts/modal';
import './RecruitManageContainer.scss';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Table as ScoreTable } from 'reactstrap';
import { ModalRecruitFooter } from 'views/domains/contents/commons/ModalFooter';
import InfoDetail from 'views/domains/contents/recruit/informationCardPack';
import _ from 'lodash';

class RecruitManageContainer extends Component {
  state = {
    batch: 0,
    rows: [],
    isDetailModal: false,
    selectedRow: [],
    keyword: '검색선택',
    query: '',
    type: '',
    applicationForm: {},
    department: '',
    score: '',
    scoreList: [],
    isScored: false, // true: 수정, false: 입력
    memo: '',
    memoList: [],
    name: '',
  };

  memoScroll = null;

  componentDidMount() {
    const recruitMeta = JSON.parse(localStorage.getItem('recruitMeta'));
    const { batch } = recruitMeta;
    this.setState({
      batch,
      department: JSON.parse(localStorage.getItem('user_session')).department,
      name: JSON.parse(localStorage.getItem('user_session')).name,
    }, () => {
      axios.getRecruitList(batch, this);
      // axios.getRecruitList({q: department === '900' ? '' : organization[department].name , type: 'department'}, this)
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  onClickRowToShowModal = async (e) => {
    const id = e.currentTarget.id;
    await axios.getRecruitDetail({ id, batch: this.state.batch }, this);
    await axios.getScoreList(id, this);
    await axios.getMemoList(id, this);
    
    const scrollHeight =  this.memoScroll.scrollHeight;
    this.memoScroll.scroll(0, scrollHeight);
  }
  
  onClickModalToClose = async () => {
    this.setState({ 
      isDetailModal: false,
      score: '',
      memo: '',
      isScored: false
    })
    const { batch } = this.state;
    // axios.getRecruitList(batch, this);
  }

  onChangeKeyword = async (e) => {
    this.setState({
      keyword: e.target.name,
      type: e.target.value,
    })
  }

  onChangeFilterQuery = async (e) => {
    const { batch, type } = this.state
    if(e.key === 'Enter') {
      if(!type) {
        alert('검색 조건을 선택해 주세요.');
        return;
      }
      const query = e.target.value.trim();
      if (query.length === 0) {
        alert('검색어를 입력해 주세요.');
        e.target.value = '';
        return;
      }
      const data = {
        batch,
        type,
        query,
      }
      await axios.searchRecuitList(data, this);
    }
  }

  onCheckRow = async (checked, id) => {
    this.setState(prevState => {
      const { applicationForm } = prevState;
      if (checked) { 
        applicationForm[id] = true;
      } else {
        delete applicationForm[id]
      }
      return applicationForm;
    });
  }

  onCheckAllRows = async (checked) => {
    this.setState(prevState => {
      const { applicationForm } = prevState;
      if (checked) {
        this.state.rows.forEach((row) => {
          applicationForm[row._id] = true;
        })
      } else {
        this.state.rows.forEach((row) => {
          delete applicationForm[row._id];
        })
      }
      return applicationForm;
    });
  }

  onChangeScore = async (e) => {
    if (e && e.target) {
      this.setState({ score: e.target.value });
    } else {
      this.setState({ score: '' });
    }
  }

  onSubmitScore = async (e) => {
    const { score, name } = this.state;
    const { _id: userId } = this.state.selectedRow;
    const numCheck = /^[0-9]/g

    if(numCheck.test(score)) {
      await axios.setScore({score: score, scoreType:'서류', writer: name, userId}, this);
    } else { 
      alert("숫자만 입력 가능합니다.");
      this.setState({ score: '' });
    }
  }

  onUpdateScore = async (e) => {
    const { score, name } = this.state;
    const { _id: userId } = this.state.selectedRow;
    const numCheck = /^[0-9]/g

    if(numCheck.test(score)) {
      await axios.updateScore({score: score, writer: name, userId}, this);
    } else { 
      alert("숫자만 입력 가능합니다.");
      this.setState({ score: '' });
    }
  }

  onChangeMemo = async (e) => {
    if (e && e.target) {
      this.setState({ memo: e.target.value });
    } else {
      this.setState({ memo: '' });
    }
  }

  onSubmitMemo = async (e) => {
    const { memo, name } = this.state;
    const { _id: userId } = this.state.selectedRow;
    await axios.setMemo({contents: memo, writer: name, userId}, this);
  }

  onEnterKeyDown = async (e) => {
    if (e && e.key === 'Enter' && e.target.id === 'submitMemo') this.onSubmitMemo();
    else if (e && e.key === 'Enter' && e.target.id === 'submitScore') this.onSubmitScore();
    else if (e && e.key === 'Enter' && e.target.id === 'updateScore') this.onUpdateScore();
  }

  onDownloadCsv = async () => {
    const { applicationForm, rows } = this.state;
    if (Object.entries(applicationForm).length === 0 && applicationForm.constructor === Object) return alert('선택된 지원서가 없습니다.');
    const list = Object.keys(applicationForm).map(id => {
      const index = _.findIndex(rows, o => {
        return o._id === id;
      });
      const data = rows[index];
      return (
        `${data.name},${data.englishName},${data.gender === 'male' ? '남' : data.gender === 'female' ? '여' : '기타'},`+
        `${data.birthDate},${data.phoneNumber},${data.email},`+
        `${data.academicName},${data.major},${data.entranceYear},${data.graduationYear},`+
        `${data.address},`+
        `${data.departmentName_1} ${data.teamName_1},`+
        `${data.departmentName_2} ${data.teamName_2},`+
        `${data.medicalField_1 || ''}, ${data.medicalField_2},`+
        `${data.otherAssignNgo ? 'O' : 'X'},${data.otherAssignMedical ? 'O' : 'X'},`+
        `${data.evaluation || ''}`
      );
    });
    const csvData = `이름,영문이름,성별,생년월일,전화번호,Email,학교,학과,입학연도,졸업연도,주소,1지망,2지망,사업 1지망,사업 2지망,타팀 배정,타사업 배정,평가상태\n${list.join('\n')}`;
    const csvDownload = document.createElement('a');
    csvDownload.href = 'data:text/csv;utf-8,\uFEFF' + encodeURIComponent(csvData);
    csvDownload.target = '_blank';
    csvDownload.download = '지원자.csv';
    csvDownload.click();
    csvDownload.remove();
  }

  onShowAll = async () =>{
    const { batch } = this.state;
    await axios.getRecruitList(batch, this);
  }

  render() {
    const { match } = this.props
    const { rows, selectedRow, scoreList, memoList } = this.state

    const questionAddBtn = (
      <Button 
        className={`btn`}
        color="secondary"
        outline
        size={`sm`}
        onClick={this.onDownloadCsv}> 
        CSV 다운로드
      </Button>
    )

    const showAllListBtn = (
        <Button
            style={{width:'120px', marginLeft:'10px'}}
            className={`btn`}
            color="secondary"
            outline
            size={`sm`}
            onClick={this.onShowAll}>
          전체 목록 보기
        </Button>
    )

    const ScoreInputBtn = (
      <div className={`RecruitManageContainer__ScoreBox__input`}>
      { this.state.isScored ? 
      <InputGroup size={`sm`}>
        <Input
          id="updateScore"
          onKeyDown={this.onEnterKeyDown}
          onChange={this.onChangeScore}
          value={this.state.score}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={this.onUpdateScore}>
            수정
          </Button>
        </InputGroupAddon>
      </InputGroup>
      :
      <InputGroup size={`sm`}>
        <Input 
          id="submitScore"
          onKeyDown={this.onEnterKeyDown}
          onChange={this.onChangeScore}
          value={this.state.score}
        />
        <InputGroupAddon addonType="append">
          <Button onClick={this.onSubmitScore}>
            입력
          </Button>
        </InputGroupAddon>
      </InputGroup>
      }
      </div>
    );

    const ModelScoreList = scoreList.map(item => {
      return (
        <tr key={item._id} >
          <td>{item.writer}</td>
          <td>{item.score}</td> 
        </tr>
      );
    });

    const ModelMemoList = memoList.map(item => {
      return (
        <div className={`RecruitManageContainer__MemoBox__contents`} key={item._id}>
          <div>
            { item.writer }: {item.contents}
          </div>
          <div className={`time`}>
            { new Date(item.registedDate).toLocaleString() }
          </div>
        </div>
      );
    });

    const ModalContent = (
      <>
        <InfoDetail selectedRow={selectedRow}/>

        <div className={`RecruitManageContainer`}>

          <span className="Title">평가</span>
          <div className={`RecruitManageContainer__ScoreBox__list`}>
            <ScoreTable size={`sm`}>
              <thead>
                <tr>
                  <th>작성자</th>
                  <th>점수</th>
                </tr>
              </thead>
              <tbody>
                { ModelScoreList }
              </tbody>
            </ScoreTable>
            { ScoreInputBtn }
          </div>

          <span className="Title">메모</span>
          <div 
            className={`RecruitManageContainer__MemoBox__list`}
            ref={ref => this.memoScroll = ref}
          >
            { ModelMemoList }
          </div>

          <InputGroup className={`RecruitManageContainer__MemoBox__input`}>
            <Input 
              id="submitMemo"
              onKeyDown={this.onEnterKeyDown}
              onChange={this.onChangeMemo} 
              value={this.state.memo}
            />
            <InputGroupAddon addonType="append">
              <Button onClick={this.onSubmitMemo}>
                등록
              </Button>
            </InputGroupAddon>
          </InputGroup>

        </div>
      </>
    );

    const ModalFooter = (
      <ModalRecruitFooter
        userSession = { JSON.parse(localStorage.getItem('user_session')) }
        selectedRow={ this.state.selectedRow }
        onClickEvaluation = { this.onClickEvaluation}
        onClickModalToClose = {this.onClickModalToClose}
      />
    )

    return (
      <div>
        {
          match.params.type === 'info' &&
          <Table
            applicationForm={this.state.applicationForm}
            type={this.props.type}
            title={'지원서 관리'}
            rows={rows}
            questionAddBtn={questionAddBtn}
            showAllListBtn = {showAllListBtn}
            onClickRow={this.onClickRowToShowModal}
            onCheckRow={this.onCheckRow}
            onCheckAllRows={this.onCheckAllRows}
            onSearchTag={this.onSearchTag}
            onChangeKeyword={this.onChangeKeyword}
            onChangeFilterQuery={this.onChangeFilterQuery}
            keyword={this.state.keyword}
            cursor
          />
        }
          <Modal
            modalType={'recruit'}
            open={this.state.isDetailModal}
            onClose={this.onClickModalToClose}
            title={'지원서'}
            contents={ModalContent}
            footer={ModalFooter}
          />
      </div>
    )
  }
}

export default RecruitManageContainer
