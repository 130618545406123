import React, { Component } from 'react';
import TableContentTemplate from 'views/contexts/templates/tableTemplate'
import Navigation from 'views/contexts/table/navigation'
import Header from 'views/contexts/table/header'
import Body from 'views/contexts/table/body'
import Pagination from 'views/contexts/table/pagination'
import * as Columns from 'lib/service/tableColumn'

import './Table.scss'

class Table extends Component {
  state = {
    currentPage: 1,
    totalPage: 0,
    rowsPerPage: 1000,
  };

  onChangePage = (currentPage) => {
    this.setState({ currentPage });
  };

  render() {
    const userSession = JSON.parse(localStorage.getItem('user_session'))
    const { 
      applicationForm,
      onClickRow, 
      questionAddBtn,
      showAllListBtn,
      title, 
      rows, 
      type, 
      onSearchTag, 
      onChangeKeyword, 
      keyword, 
      onChangeFilterQuery, 
      timeTable, 
      attributeData = [],
      onCheckRow = ()=>{} ,
      onCheckAllRows,
    } = this.props;
    const { currentPage, rowsPerPage } = this.state;
    const columns = Columns[type]
    return (
      <>
      <div className={'Table__header'}>
        <div className={'Table__titlebar'} style={type === 'question' ? {marginBottom: '20px'} : {}}>{title}</div>
      </div>
        <TableContentTemplate navigation={type === 'applyInfo' &&
          <Navigation
            questionAddBtn={questionAddBtn}
            showAllListBtn={showAllListBtn}
            onClickSearchTag={onSearchTag}
            onChangeKeyword={onChangeKeyword}
            onChangeFilterQuery={onChangeFilterQuery}
            keyword={keyword}
            userSession={userSession}
          />
        }>
          <Header
            onCheckAllRows={onCheckAllRows}
            columns={columns}
            timeTable={timeTable}
          />
          <Body
            applicationForm={applicationForm}
            type={type}
            columns={columns}
            rows={[ ...rows].splice((currentPage-1) * rowsPerPage , rowsPerPage) } 
            onClickRow={onClickRow}
            onCheckRow={onCheckRow}
            rowsPerPage={rowsPerPage}
            attributeData={attributeData}
          />
        </TableContentTemplate>
      </>
      );
  }
}

export default Table
