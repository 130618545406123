import axiosCreate from '../defaultAxios'

export const getRecruitList = (batch, ctx) => {
  return axiosCreate().get(`/admin/applicant2/${batch}?`)
    .then(res => res.status === 200 && ctx.setState({ rows: res.data.result, isDetailModal: false}))
    .catch(err => err)
}

export const searchRecuitList = (data, ctx) => {
  return axiosCreate().post(`/admin/search/application`, data)
    .then(res => res.status === 200 && ctx.setState({ rows: res.data.result, isDetailModal: false}))
    .catch(err => err)
}

export const getRecruitDetail = ({id, batch}, ctx) => {
  return axiosCreate().get(`/admin/applicant2/${batch}/${id}`)
    .then(res => ctx.setState({
      selectedRow: res.data.result,
      isDetailModal: true,
    }))
    .catch(err => err)
}

export const setMemo = (data, ctx) => {
  return axiosCreate().post(`/admin/memo/${data.userId}`, data)
  .then(({ data }) => {
    if (data && data.result) {
      ctx.setState(prevState => {
        const { memoList } = prevState;
        memoList.push(data.result);
        return memoList;
      }, () => {
        ctx.onChangeMemo();
      });
    } else {
      alert('메모 작성에 실패하였습니다.');
    }
  })
  .catch(err => err);
}

export const getMemoList = (id, ctx) => {
  return axiosCreate().get(`/admin/memo/${id}`)
  .then(({ data }) => {
    if (data && data.result) {
      ctx.setState({memoList: data.result})
    }
  })
  .catch(err => err);
}

export const setScore = (data, ctx) => {
  return axiosCreate().post(`/admin/score/${data.userId}`, data)
  .then(({ data }) => {
    if (data && data.result) {
      ctx.setState(prevState => {
        const { scoreList } = prevState;
        scoreList.push(data.result);
        return scoreList;
      }, () => {
        ctx.setState({isScored: true})
        ctx.onChangeScore();
      });
    } else {
      alert('점수 입력에 실패하였습니다.');
    }
  })
  .catch(err => err);
}

export const getScoreList = (id, ctx) => {
  return axiosCreate().get(`/admin/score/${id}`)
  .then(({ data }) => {
    if (data && data.result) {
      ctx.setState({scoreList: data.result})
      if (ctx.state.scoreList.length===0) {
        ctx.setState({isScored: false})
      }
      else {
        ctx.state.scoreList.some(item => {
          if (item.writer === ctx.state.name) {
            return ctx.setState({isScored: true})
          }
        })
      }
    }
  })
  .catch(err => err);
}

export const updateScore = (data, ctx) => {
  return axiosCreate().put(`/admin/score/${data.userId}`, data)
  .then(({ data }) => {
  if (data && data.result) {
    const updateScore = data.result;
    ctx.setState(prevState => {
      const { scoreList } = prevState;
      let index = null;
      scoreList.some((score, scoreIndex) => {
        if (score.writer === updateScore.writer && score.userId === updateScore.userId) {
          index = scoreIndex;
          return true;
      }
      return false;
    });
      scoreList[index].score = updateScore.score;
      return scoreList;
    }, () => {
      ctx.onChangeScore();
    });
  } else {
    alert('점수 수정에 실패하였습니다.');
  }
})
.catch(err => err);
}