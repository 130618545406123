import React, { Component, Fragment } from 'react'
import DashBoard from '../DashBoard'
import * as axios from 'lib/api/chart'

class DashBoardViewerContainer extends Component {
  state = {
    applierStat: null
  }

  componentDidMount () {
    const recruitMeta = JSON.parse(localStorage.getItem('recruitMeta'));
    const { batch } = recruitMeta;
    axios.getApplierStat(batch, this);
  }

  render() {
    return (
      <Fragment>
        <DashBoard applierStat={this.state.applierStat}/>
      </Fragment>
    )
  }
}

export default DashBoardViewerContainer